<template>
  <v-card flat class="pb-16 mb-2">
    <v-dialog
      v-model="showCreateTask"
      max-width="600"
    >
      <v-card>
        <v-card-title>Create Task</v-card-title>
        <v-card-text>
          <v-form v-if="task">
            <v-text-field
              label="Title"
              v-model="task.title"/>
            <v-textarea
              label="Description"
              v-model="task.description"
            />
            <!--            <v-combobox-->
            <!--                label="Team"-->
            <!--                v-model="task.team"-->
            <!--                :items="teams"-->
            <!--            />-->
            <!--            <v-text-field label="Team"-->
            <!--                          disabled-->
            <!--                          v-model="task.team"-->
            <!--            />-->
            On which dates should this task appear? To show on the current page of tasks, do not change this.
            <v-text-field :value="datesFormatted"
                          label="Select dates"
                          single-line
                          hide-details
                          readonly
                          @click="datePickerDates=task.dates || dates; showDatePicker=true"
                          append-icon="mdi-calendar"
            />

            <v-dialog
              v-model="showDatePicker"
              ref="dialog"
              width="290px"
              persistent
            >
              <v-date-picker
                v-model="datePickerDates"
                range
                no-title
                @close="closeDatePicker"
              ></v-date-picker>
              <v-btn @click="closeDatePicker">Close</v-btn>
            </v-dialog>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showCreateTask=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="createTask(task)">Create</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showConfirmStart"
      max-width="600"
    >
      <v-card v-if="confirmTask">
        <v-card-title>
          {{ confirmTask.title }}
          <v-spacer/>
          <v-btn v-if="confirmTask.complete && hasStickers(confirmTask)" outlined
                 @click="showStickers">Stickers
          </v-btn>
        </v-card-title>
        <slot
          name="task-confirm-extra"
          v-bind:props="{confirmTask}"
        >
          <!--          <h2>Extra here</h2>-->
        </slot>

        <v-card-text>
          <ul>
            <li v-for="(line,i) of (confirmTask.description||'').split('\n')" v-bind:key="i">{{ line }}</li>
          </ul>
          <template v-if="confirmTask.execution && confirmTask.execution.length>0">
            Who worked on this:
            <ul>
              <li v-for="execution of confirmTask.execution" v-bind:key="execution.id">
                {{ execution.team_members.map(m => m.name).join(',') }}
                {{ formatDuration(getDuration(execution.start, execution.stop), true) }} minutes
              </li>
            </ul>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-icon outline>mdi-plus</v-icon>
          to add new team members.
          <v-icon>mdi-minus</v-icon>
          to delete.
          <v-spacer/>
          <v-btn class="mr-2" @click="addTeamMember" small outlined color="green" fab icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn @click="confirmTeamRemoval" small outlined color="red" fab icon>
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-title>
          Please select who will work on this
        </v-card-title>

        <v-card-text>
          <v-chip-group
            column
            multiple
            v-model="selectedTeamMemberIndicies"
            active-class="deep-purple--text darken-3 text--accent-4"
          >
            <v-chip
              v-for="teamMember of activeTeamMembers" v-bind:key="teamMember.id"
              outlined>
              {{ teamMember.name }}
            </v-chip>
          </v-chip-group>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="confirmTask=null;showConfirmStart=false">Close</v-btn>
          <v-spacer/>
          <v-btn :disabled="selectedTeamMembers.length===0" @click="startConfirmed">Confirm Start</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showConfirmStop"
      max-width="600"
    >
      <v-card v-if="confirmTask">
        <!--        <pre>{{confirmTask}}</pre>-->
        <v-card-title>
          {{ confirmTask.title }}
          <v-spacer/>
          <v-btn v-if="hasStickers(confirmTask)" outlined
                 @click="showStickers">Stickers
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(line,i) of (confirmTask.description||'').split('\n')" v-bind:key="i">{{ line }}</li>
          </ul>
          <v-chip-group>
            <v-chip v-for="teamMember of currentTeamWorkingOnTask(confirmTask)" v-bind:key="teamMember">
              {{ teamMember }}
            </v-chip>
          </v-chip-group>
          Time working on this: {{ formatDuration(timeElapsed[confirmTask.id]) }}
        </v-card-text>
        <v-card-text>
          <v-textarea
            label="Enter a note (optional)"
            v-model="confirmTask.note"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="confirmTask=null; showConfirmStop=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn @click="stopConfirmed({complete:false})">Pause</v-btn>
          <v-spacer/>
          <v-btn @click="stopConfirmed({complete:true})">Complete</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <template v-if="!hidden">
      <!-- loadingTasks===0 because it can display a misleading "disconnect" when waiting a long time to fetch tasks -->
      <v-alert v-if="isDisconnected && loadingTasks===0" type="warning">
        Disconnected from Server. Tasks will not update!
      </v-alert>
      <v-toolbar flat>
        <v-btn v-if="!denseView && !createTaskButtonHidden" x-large text outlined @click="showCreateTask=true">Create
          Task
        </v-btn>
        <v-spacer/>
        <v-btn-toggle :dense="denseView" v-model="viewMode" mandatory v-if="!hideTaskStatusFilter">
          <v-btn value="all">All {{ showCount(allTasks) }}</v-btn>
          <v-btn value="open">Open {{ showCount(openTasks) }}</v-btn>
          <v-btn value="running">Running {{ showCount(runningTasks) }}</v-btn>
          <v-btn value="paused">Paused {{ showCount(pausedTasks) }}</v-btn>
          <v-btn value="complete">Complete {{ showCount(completedTasks) }}</v-btn>
        </v-btn-toggle>
        <v-spacer/>
        <v-checkbox
          label="show data"
          v-model="showData"
        />
      </v-toolbar>
      <v-card-title v-if="!loadingTasks && !denseView">{{ tasks.length }} Tasks</v-card-title>
      <v-card-text v-if="loadingTasks">
        Loading tasks...
        <v-alert type="info" class="mt-10">
          Loading tasks can take a while as it has to count all the orders.
          <br/><br/>
          Please be patient, it should not take more than a minute.
        </v-alert>
      </v-card-text>
      <template v-if="!loadingTasks">
        <v-card-text v-if="showData">
          <v-btn outlined @click="csvExport(dataAsTable,exportFilename())">Download CSV</v-btn>
          <br/>
          <v-data-table
            :headers="dataHeaders"
            :items="dataAsTable"
            disable-pagination
            hide-default-footer
            dense
          />
        </v-card-text>
        <v-card-text v-if="!showData">
          <v-row class="">
            <v-col :cols="denseView?6:4" v-for="task of tasks" v-bind:key="task.id" class="pa-2"
                   style="page-break-inside: avoid">
              <v-card
                @click="onCardClicked(task)"
                class="ma-0 pa-0"
                :class="`${(task.complete?'task-complete-card':'')} ${production}-production`"
              >
                <slot
                  name="task-title"
                  v-bind:props="{task}"
                >
                  <v-card-title>
                    {{ task.title }}
                    <v-spacer/>
                    <v-icon v-if="task.complete">mdi-check</v-icon>
                  </v-card-title>
                </slot>
                <slot
                  name="task-text"
                  v-bind:props="{ task }"
                >
                  <v-card-text>
                    <ul>
                      <li v-for="(line,i) of (task.description||'').split('\n')" v-bind:key="i">{{ line }}</li>
                    </ul>
                  </v-card-text>
                </slot>
                <slot
                  name="task-content"
                  v-bind:props="{ task }"
                >
                  <v-card-text v-if="!denseView && !hideDurationTable">
                    <v-simple-table dense>
                      <thead>
                      <tr>
                        <th>Duration (minutes)</th>
                        <th>Start</th>
                        <th>Stop</th>
                        <th>People</th>
                        <th>Note</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="execution of task.execution" v-bind:key="execution.id">
                        <td>{{ formatDuration(getDuration(execution.start, execution.stop)) }}</td>
                        <td>
                          {{ dateFormatted(execution.start, {formatString: timeFormat}) }}
                        </td>
                        <td>{{
                            execution.stop ? dateFormatted(execution.stop, {formatString: timeFormat}) : '...'
                          }}
                        </td>
                        <td>
                          {{ execution.team_members.map(m => m.name).join(',') }}
                        </td>
                        <td>{{ execution.note }}</td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                    <v-chip-group>
                      <v-chip v-for="(teamMember,i) of teamAssignments[task.id]" v-bind:key="i">{{ teamMember.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </slot>
                <slot name="task-actions"
                      v-bind:props="{ task }">
                  <v-card-actions v-if="!denseView">
                    {{ formatDuration(timeElapsed[task.id]) }}
                    <v-spacer/>
                    <v-btn x-large icon class="green" v-if="!isStarted(task.id)  && !isPaused(task)"
                           @click="startConfirm(task)">
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                    <v-btn x-large icon class="yellow" v-if="!isStarted(task.id) && isPaused(task)"
                           @click="startConfirm(task)">
                      <v-icon>mdi-pause</v-icon>
                    </v-btn>
                    <v-btn x-large icon class="red" v-if="isStarted(task.id)" @click="stopConfirm(task)">
                      <v-icon>mdi-stop</v-icon>
                    </v-btn>
                  </v-card-actions>
                </slot>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <v-footer
        fixed
        v-if="!loadingTasks"
        style="font-size: 2vh"
      >
        <v-row>
          <v-col align-self="center" cols="1">
            {{ dateFormatted(now, {formatString: 'HH:mm:ss'}) }}
          </v-col>
          <template v-if="showLateWarnings">
            <v-col
              v-for="priority of activePriorities" v-bind:key="priority" class="text-center"
              :class="isComplete(priority) ?  '' : isLateClass(priority, now)"
            >
              (P{{ priority }})
              <!--            <div v-for="t of  tasksByPriority(priority)" v-bind:key="t.id">-->
              <!--              {{t.title}} {{ t.production_priority}} {{ t.complete }}-->
              <!--            </div>-->
              <span v-if="countPriorityLeft(priority)">{{ countPriorityLeft(priority) }} left, </span>
              {{ percentPriorityComplete(priority) }}%
              <div v-if="isLate(priority,now) && !isComplete(priority)">
                LATE {{ formatDate(priorityDeadline[priority]) }}
                <!--                - finish before {{ priorityDeadline[priority] }}:00-->
                <!--              <br/>-->
                <v-icon large color="red">mdi-emoticon-cry-outline</v-icon>

              </div>
              <span v-if="isLate(priority,now) && isComplete(priority)">
                  <v-icon large color="orange">mdi-emoticon-cool-outline</v-icon>
              </span>
            </v-col>
          </template>
        </v-row>
      </v-footer>
    </template>
  </v-card>
</template>

<script>
import {csvExport, dateFormatted, formatDuration} from '@/store/utils';
import moment from 'moment';
import urlState from "@/router/urlState";
import {mapState} from "vuex";
import api from "@/api";

export default {
  name: "TaskPanel",
  mixins: [urlState],
  props: {
    tasksIn: {type: Array, default: null, required: false},
    taskConfirmStart: {type: Object, default: null, required: false},
    taskConfirmStop: {type: Object, default: null, required: false},
    view: {type: String},
    dates: {type: Array, required: false, default: () => []},
    production: {type: String, required: false, default: '2'},
    team: {type: String},
    search: {type: String},
    filter: {type: String},
    hidden: {type: Boolean, default: false, required: false},
    denseView: {type: Boolean, default: false, required: false},
    hideDurationTable: {type: Boolean, default: false, required: false},
    cardClickedHandler: {type: Function, default: null, required: false},
    createTaskButtonHidden: {type: Boolean, default: false, required: false},
    hideTaskStatusFilter: {type: Boolean, default: false, required: false},
    dataAsTableOverride: {type: Array, default: null, required: false},
    exportFile: {type: String, default: null, required: false},
    showLateWarnings: {type: Boolean, default: false, required: false},
    lateWarningPriorityDeadlines: {type: Object, default: null, required: false},
    sortFn: {type: Function, default: null, required: false},
    filterFn: {type: Function, default: null, required: false},
    startConfirmFn: {type: Function, default: null, required: false},
    stopConfirmFn: {type: Function, default: null, required: false},
    searchAll: {type: Boolean, default: false, required: false},
    focusRunning: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      team_id_promise: null,
      now: moment(),
      viewMode: 'open',
      taskMap: {},
      teams: [],
      timerIntervals: {},
      timeElapsed: {},
      timeStarted: {},
      timeStopped: {},
      confirmTask: null,
      showConfirmStart: null,
      showConfirmStop: null,
      selectedTeamMemberIndicies: [],
      teamMembers: [],
      teamAssignments: {},
      showAddTask: null,
      loadingTasks: 0,
      showData: false,
      timeFormat: 'HH:mm:ss ddd',
      showCreateTask: false,
      task: {
        title: '',
        description: '',
        dates: this.dates && JSON.parse(JSON.stringify(this.dates)),
        team: this.namespace
      },
      datePickerDates: null,
      showDatePicker: null,
      priorityDeadline: {
        1: 11,
        2: 12,
        3: 13
      },
      isDisconnected: false,
      taskId: null,
    }
  },
  async mounted() {
    if (this.lateWarningPriorityDeadlines) {
      this.priorityDeadline = this.lateWarningPriorityDeadlines;
    }
    try {
      console.log('connecting ', {namespace: this.namespace});
      this.team_id_promise = api.get(`v2/task/team/${this.team}`)
        .then(({data}) => {
          console.log('retrieved team', data);
          return data.id;
        });
      if (!this.tasksIn && this.dates) {
        console.log('task panel fetching tasks', this.dates);
        this.fetchAllTasks();
      } else {
        console.log('task panel using property tasksIn', this.tasksIn);
        this.tasksIn.forEach(t => this.updateTask(t));
      }
      if (this.view) {
        this.viewMode = this.view;
      }
      this.fetchTeamMembers();
      this.$socket.on('connect', () => this.isDisconnected = false);
      this.$socket.on('disconnect', () => this.isDisconnected = true);
      this.$socket.on('task:started', (task) => this.startedTimer(task));
      this.$socket.on('task:stopped', (task) => this.stopTimer(task));
      this.$socket.on('task:updated', (task) => this.updateTask(task));
      this.$socket.on('task:created', (task) => this.addTask(task));
      this.syncToUrl({
        param: 'viewMode', urlParam: 'view', initFromRoute: true,
        // parseCallback: (v) => v === 'true'
      });
      this.syncToUrl({
        param: 'showData', urlParam: 'data', initFromRoute: true,
        parseCallback: (v) => v === 'true'
      });
      this.syncToUrl({
        param: 'taskId', urlParam: 'task', initFromRoute: true,
        parseCallback: (v) => v ? Number(v) : null
      });
    } catch (e) {
      console.error('socket failed', e);
    }

    const intervalId = setInterval(() => {
      this.now = moment();
    }, 1000);
    this.$set(this.timerIntervals, 'main-clock', intervalId);
  },
  watch: {
    runningTasks(t) {
      console.log('running tasks changed', t);
      if (this.focusRunning) {
        if (t.length > 0) {
          this.viewMode = 'running';
        } else {
          this.viewMode = 'all';
        }
      }
    },
    showConfirmStop(v) {
      if (!v) {
        this.taskId = null;
        this.$emit('close-stop-confirm');
      }
    },
    showConfirmStart(v) {
      if (!v) {
        this.taskId = null;
        this.$emit('close-start-confirm');
      }
    },
    confirmTask(val) {
      if (val) {
        this.taskId = val.id;
      } else {
        this.taskId = null
      }
    },
    taskId: 'checkTaskId',
    isDisconnected(val, old) {
      if (!val && old) {
        this.loadingTasks = 0;
        console.log('reconnected, refetch tasks', this.loadingTasks);
        this.fetchAllTasks();
      } else {
        console.log('isDisconncted skip, ', {val, old});
      }
    },
    tasks(val) {
      this.$emit('update:tasks', val);
    },
    taskConfirmStart(val) {
      if (val) {
        // console.log('taskConfirmStart(val)', val);
        this.startConfirm(val);
      }
    },
    taskConfirmStop(val) {
      if (val) {
        // console.log('taskConfirmStop(val)', val);
        this.stopConfirm(val);
      }
    },
    showCreateTask(val) {
      if (val) {
        this.task = {
          title: '',
          description: '',
          dates: JSON.parse(JSON.stringify(this.dates)),
          team: this.namespace
        }
      }
    },
    tasksIn(tasks) {
      this.taskMap = {};
      // stop the intervals
      Object.values(this.timerIntervals)
        .forEach(timer => clearInterval(timer));
      tasks.forEach(t => this.updateTask(t));
    },
    dates(newDates, oldDates) {
      if (JSON.stringify(newDates) === JSON.stringify(oldDates)) {
        // not a real change - perhaps triggered by route (eg search)
        return;
      }
      this.refetchTasks()
    },
    production: 'refetchTasks'
  },
  destroyed() {
    // for all events
    console.log('removing listeners/intervals');
    this.$socket.removeAllListeners();
    Object.values(this.timerIntervals)
      .forEach(timer => clearInterval(timer));
  },
  computed: {
    ...mapState(['productionPriorities']),
    showCompleted() {
      return !this.hideTaskStatusFilter && this.viewMode === 'complete'
    },
    showWeighed() {
      return !this.hideTaskStatusFilter && this.viewMode === 'weighed'
    },
    showRunning() {
      return !this.hideTaskStatusFilter && this.viewMode === 'running'
    },
    showOpen() {
      return !this.hideTaskStatusFilter &&  this.viewMode === 'open'
    },
    showPaused() {
      return !this.hideTaskStatusFilter && this.viewMode === 'paused'
    },
    isStarted() {
      return (id) => {
        return this.timerIntervals[id];
      }
    },
    selectedTeamMembers() {
      // console.log('selectedTeamMemberIndicies',this.selectedTeamMemberIndicies)
      const result = this.selectedTeamMemberIndicies.map(index => this.activeTeamMembers[index]);
      // console.log('selectedTeamMembers',result)
      return result;
    },
    openTasks() {
      return this.allTasks.filter(t => !this.isStarted(t.id) && !t.complete);
    },
    runningTasks() {
      return this.allTasks.filter(t => this.isStarted(t.id));
    },
    pausedTasks() {
      return this.allTasks.filter(t => this.isPaused(t));
    },
    completedTasks() {
      return this.allTasks.filter(t => t.complete);
    },
    weighedTasks() {
      return this.completedTasks.filter(t => !!t.weight);
    },
    allTasksUnfiltered() {
      return Object.values(this.taskMap);
    },
    allTasksFiltered() {
      let tasks = this.allTasksUnfiltered;
      let filterTerms = (this.filter || '').split(' ').filter(s => !!s).map(s => s.trim().toLowerCase());

      function containsKey(t, search) {
        return t.title.toLowerCase().indexOf(search) > -1 || t.description.toLowerCase().indexOf(search) > -1;
      }

      if (filterTerms.length > 0) {
        tasks = tasks.filter(t => !filterTerms.some(term => containsKey(t, term)));
      }
      return tasks;
    },
    allTasks() {
      let tasks = this.allTasksUnfiltered;

      // console.log('tasks pre filter', tasks);
      // console.log('tasks post viewmode', tasks, [this.showCompleted, this.showRunning, this.showOpen, this.showPaused]);
      let searchTerms = (this.search || '').split(' ').filter(s => !!s).map(s => s.trim().toLowerCase());
      let filterTerms = (this.filter || '').split(' ').filter(s => !!s).map(s => s.trim().toLowerCase());

      // console.log('filter terms', filterTerms);

      function containsKey(t, search) {
        return t.title.toLowerCase().indexOf(search) > -1 || t.description.toLowerCase().indexOf(search) > -1;
      }

      if (searchTerms.length > 0) {
        if (this.searchAll) {
          console.log('search all', searchTerms)
          tasks = tasks.filter(t => searchTerms.every(term => containsKey(t, term)));
        } else {
          console.log('search any', searchTerms);
          tasks = tasks.filter(t => searchTerms.some(term => containsKey(t, term)));
        }
      }

      if (filterTerms.length > 0) {
        tasks = tasks.filter(t => !filterTerms.some(term => containsKey(t, term)));
      }

      // console.log('tasks post filter', tasks);

      if (this.filterFn) {
        tasks = tasks.filter(this.filterFn);
      }

      if (this.sortFn) {
        return tasks.sort(this.sortFn);
      } else {
        return tasks.sort((t1, t2) => t1.title.localeCompare(t2.title));
      }
    },
    tasks() {
      if (this.showCompleted) {
        return this.completedTasks;
      }
      if (this.showRunning) {
        return this.runningTasks;
      }
      if (this.showOpen) {
        return this.openTasks;
      }
      if (this.showPaused) {
        return this.pausedTasks;
      }
      if (this.showWeighed) {
        return this.weighedTasks;
      }
      return this.allTasks;
    },
    dataAsTable() {
      if (this.dataAsTableOverride) {
        return this.dataAsTableOverride;
      }
      const rows = [];
      for (const task of this.tasks) {
        const {id, title, elapsed, description} = task;
        if (!task.execution || task.execution.length === 0) {
          rows.push({
            taskId: id,
            title,
            totalDurationInMinutes: this.formatDuration(elapsed, true),
            people: '',
            durationInMinutes: '',
            start: '',
            stop: '',
            note: '',
            description,
          });
        } else {
          for (const execution of task.execution) {
            const {start, stop, team_members, note} = execution;
            rows.push({
              taskId: id,
              title,
              totalDurationInMinutes: this.formatDuration(elapsed, true),
              people: team_members.map(t => t.name).join(', '),
              durationInMinutes: this.formatDuration(this.getDuration(start, stop), true),
              start: dateFormatted(start, {formatString: this.timeFormat}),
              stop: dateFormatted(stop, {formatString: this.timeFormat}),
              note,
              description,
            });
          }
        }
      }
      console.log('rows', rows);
      return rows;
    },
    dataHeaders() {
      function format(text) {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
      }

      return Object.keys(this.dataAsTable[0] || {})
        .map(k => ({text: format(k), value: k}));
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    activeTeamMembers() {
      return this.teamMembers.filter(m => !m.inactive);
    },
    activePriorities() {
      return this.productionPriorities
        .filter(p => this.countPriority(p) > 0)
    },
  },
  methods: {
    refetchTasks() {
      this.taskMap = {};
      // stop the intervals
      Object.values(this.timerIntervals)
        .forEach(timer => clearInterval(timer));
      const intervalId = setInterval(() => {
        this.now = moment();
      }, 1000);
      this.$set(this.timerIntervals, 'main-clock', intervalId);
      this.fetchAllTasks();
    },
    hasStickers(confirmTask) {
      return confirmTask.source && !!confirmTask.source.counts;
    },
    isLate(priority, now) {
      const hour = now.hour();
      return (hour >= this.priorityDeadline[priority]);
    },
    isLateClass(priority, now) {
      if (this.isLate(priority, now) && now.second() % 2 === 1) {
        return 'task-late-status';
      } else {
        return '';
      }
    },
    isInDateRange(dates, date) {
      dates = [...dates].sort();
      const from = dates[0];
      const to = dates[dates.length - 1];
      return moment(date).isBetween(from, to, 'day', '[]')
    },
    createTask(task) {
      console.log('createTask', task);
      task.from = this.dateFrom;
      task.to = this.dateTo;
      task.team = this.team;
      // task.from = moment(this.dateFrom).startOf('day').toISOString();
      // task.to = moment(this.dateTo).endOf('day').toISOString();
      if (task.title) {
        this.$socket.emit('task:create', task, response => {
          console.log('create task response', response);
        });
        this.showCreateTask = false;
      }
    },
    startedTimer(task) {
      let {id, start} = task;
      // console.log('start timer', task);
      if (!this.taskMap[id]) {
        console.log('ignoring task, not in task map', task);
        return;
      }

      this.taskMap[id] = {...this.taskMap[id], ...task};
      if (this.isStarted(id)) {
        // console.log('already started, nothing to do', {id});
      } else {
        start = Date.parse(start);
        if (!start) {
          alert('no start time!');
          return;
        }
        this.timeStarted[id] = start;
        if (this.timerIntervals[id]) {
          clearInterval(this.timerIntervals[id]);
          this.timerIntervals[id] = false;
        }
        const intervalId = setInterval(() => {
          this.updateElapsed(id);
        }, 1000);
        this.$set(this.timerIntervals, id, intervalId);
      }
    },
    start({id, team_members}) {
      console.log('task:starting - ', {id, team_members});
      this.$socket.emit('task:start', {id, team_members},
        (result) => {
          console.log('task:start - result', result);
          const {error} = result;
          if (error) {
            console.warn('failed to start');
            alert(error);
          }
        });
    },
    updateElapsed(id) {
      let elapsed = this.timeElapsed[id];
      let started = this.timeStarted[id];
      let stopped = this.timeStopped[id];
      if (started && !stopped) {
        const now = Date.now();
        elapsed += now - started;
        // a bit hacky, but since elapsed has been updated, ensure started is updated too
        this.timeStarted[id] = now;
      }
      // console.log('update elapsed', {id, elapsed, started, stopped});
      this.$set(this.timeElapsed, id, elapsed);
    },
    stopTimer(task) {
      const {id} = task;
      const stop = Date.now();
      console.log('stopping', task);
      if (!this.taskMap[id]) {
        console.log('ignoring task, not in task map', task);
        return;
      }
      this.taskMap[id] = {...this.taskMap[id], ...task};
      if (this.timeStopped[id]) {
        console.log('already stopped, nothing to do', {id});
      } else {
        this.timeStopped[id] = stop;
        this.teamAssignments[id] = [];
        if (this.timerIntervals[id]) {
          clearInterval(this.timerIntervals[id]);
          this.timerIntervals[id] = false;
        }
        this.updateElapsed(id);
      }
    },
    stop({id, complete, note}) {
      console.log('stop', 'task:stop', {id, complete, note});
      this.$socket.emit('task:stop', {id, complete, note}, (result) => {
        console.log('task:stop - result', result);
      });
    },
    formatDuration,
    dateFormatted,
    startConfirm(item) {
      this.showConfirmStart = true;
      this.confirmTask = item;
      this.selectedTeamMemberIndicies = [];
    },
    stopConfirm(item) {
      this.showConfirmStop = true;
      this.confirmTask = item;
      this.taskId = item.id;
    },
    stopConfirmed({complete}) {
      const confirmTask = this.confirmTask;
      if (this.stopConfirmFn) {
        complete = this.stopConfirmFn(confirmTask, complete);
      }
      const {id, note} = confirmTask;
      this.stop({id, complete, note});
      this.confirmTask = null;
      this.showConfirmStop = false;
    },
    startConfirmed() {
      const confirmTask = this.confirmTask;
      const id = confirmTask.id;
      const team_members = this.selectedTeamMembers.map(m => m.id);
      this.start({id, team_members});
      this.teamAssignments[id] = JSON.parse(JSON.stringify(this.selectedTeamMembers));
      this.confirmTask = null;
      this.showConfirmStart = false;
      if (this.startConfirmFn) {
        this.startConfirmFn(confirmTask);
      }
    },
    addTeamMember() {
      const teamMember = (prompt('Enter the name of a new team member ') || '').trim();
      if (teamMember) {
        this.$socket.emit('members:add', {name: teamMember, team: this.team});
      }
    },
    confirmTeamRemoval() {
      if (this.selectedTeamMembers.length === 0) {
        alert('you must select at least one team member to remove');
      } else if (confirm(`Are you sure you want to remove ${this.selectedTeamMembers.map(m => m.name)}`)) {
        this.$socket.emit('members:remove', {team: this.team, ids: this.selectedTeamMembers.map(m => m.id)});
        this.selectedTeamMemberIndicies = [];
      }
    },
    fetchTeamMembers() {
      console.log('fetching team members');
      this.$socket.emit('members:fetch',
        {team: this.team},
        (result) => {
          const {error, teamMembers} = result;
          if (error) {
            alert(`members:fetch failed: ${error}`);
          } else {
            console.log('members:fetch result', teamMembers);
            teamMembers.forEach(teamMember => this.teamMembers.push(teamMember));
            this.loadingTeams = false;
          }
          this.loadingTeams = false;
        }
      );
      this.$socket.on('members:added', member => {
        console.log('member added to team', member);
        this.teamMembers.push(member);
      })
      this.$socket.on('members:removed', members => {
        console.log('members removed from team', members);
        const ids = members.map(m => m.id);
        this.teamMembers = this.teamMembers.filter(m => !ids.includes(m.id));
      })
    },
    async fetchTasks() {
      if (this.tasksIn) {
        console.warn('fetch tasks skipped because tasks passed in');
        return;
      }
      const {dates, production} = this;

      this.loadingTasks++;
      return new Promise(resolve => {
        const fetchOptions = {
          dates,
          productions: [production],
          team: this.team
        };
        console.log('fetching tasks', fetchOptions);
        this.$socket.emit('tasks:fetch',
          fetchOptions,
          async (result) => {
            const {error, tasks} = result;
            if (error) {
              alert(`fetch tasks ${dates} failed: ${error}`);
            } else {
              console.log(`tasks:fetch - ${dates} result`, result);
              await Promise.all(tasks.map(task => this.updateTask(task)));
            }
            this.$emit('update:all:tasks', this.allTasksUnfiltered);
            this.loadingTasks--;
            resolve();
          }
        );
      });
    },
    csvExport,
    isPaused(t) {
      return !this.isStarted(t.id) && t.elapsed > 0 && !t.complete;
    },
    getDuration(start, stop) {
      start = Date.parse(start);
      stop = Date.parse(stop);
      return stop - start;
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.task.dates = this.datePickerDates;
    },
    async addTask(task) {
      const team_id = await this.team_id_promise;
      if (task.team_id !== team_id) {
        console.log('add: task not for this team', task.team_id, team_id, task);
        return;
      }
      console.log('checking if in date range', {dates: this.dates, from: task.from, to: task.to});
      const fromInRange = this.isInDateRange(this.dates, task.from);
      const toInRange = this.isInDateRange(this.dates, task.to);
      if (fromInRange || toInRange) {
        console.log('in range!');
        console.log('adding task', task);
        this.updateTask(task);
      } else {
        console.log('not in range, ignore');
      }
    },
    async updateTask(task) {
      const team_id = await this.team_id_promise;
      if (task.team_id !== team_id) {
        console.log('update: task not for this team', task.team_id, team_id, task);
        return;
      }
      const oldTask = this.taskMap[task.id] || {};
      this.$set(this.taskMap, task.id, {...oldTask, ...task});
      this.$set(this.timeElapsed, task.id, task.elapsed);
      const executing = task.execution.find(e => !e.stop)
      if (executing) {
        // console.log('starting timer for task', {task, executing});
        this.startedTimer({id: task.id, start: executing.start});
      }
    },
    fetchAllTasks() {
      // return Promise.all(this.dates.map(date =>
      this.fetchTasks()
        .then(() => this.checkTaskId);
    },
    checkTaskId() {
      console.log('checking task id set', this.taskId, this.confirmTask);
      if (this.taskId) {
        const task = this.allTasks.find(t => t.id === this.taskId);
        if (task) {
          if (this.isStarted(task.id)) {
            this.stopConfirm(task);
          } else {
            this.startConfirm(task);
          }
        } else {
          console.log('could not find task in ', this.allTasks);
        }
      }
    },
    showCount(tasks) {
      if (tasks && tasks.length > 0) {
        return `(${tasks.length})`;
      }
    },
    onCardClicked(task) {
      if (this.cardClickedHandler) {
        this.cardClickedHandler(task);
      } else if (this.isStarted(task.id)) {
        this.stopConfirm(task)
      } else {
        this.startConfirm(task)
      }
    },
    exportFilename() {
      if (this.exportFile) {
        return this.exportFile;
      }
      return `tasks-${this.dates.join('-')}-${this.production}`;
    },
    getPriority(t) {
      return t.source && t.source.production_priority;
    },
    tasksByPriority(priority) {
      return this.allTasksFiltered.filter(t => this.getPriority(t) === priority);
    },
    countPriority(priority) {
      return this.tasksByPriority(priority).length;
    },
    countPriorityCompleted(priority) {
      return this.tasksByPriority(priority).filter(t => t.complete).length;
    },
    countPriorityLeft(priority) {
      return this.countPriority(priority) - this.countPriorityCompleted(priority);
    },
    isComplete(priority) {
      return this.countPriorityLeft(priority) === 0;
    },
    percentPriorityComplete(priority) {
      return ((this.countPriorityCompleted(priority) / this.countPriority(priority)) * 100).toFixed(0);
    },
    formatDate(hour) {
      const d = moment(hour, 'H');
      if (d.isValid())
        return d.format('ha')
      else
        return hour;
    },
    showStickers() {
      this.showConfirmStart = false;
      this.showConfirmStop = false;
      const query = {...this.$route.query, task: null, sticker: this.taskId};
      this.$router.replace({query})
      this.$emit('print-stickers', this.confirmTask)
    },
    currentDurationWorkingOnTask(task) {
      if (task.execution && task.execution.length > 0) {
        const e = task.execution[task.execution.length - 1];
        return formatDuration(this.getDuration(e.start, e.stop));
      } else {
        return [];
      }
    },
    currentTeamWorkingOnTask(task) {
      if (task.execution && task.execution.length > 0) {
        return task.execution[task.execution.length - 1].team_members.map(m => m.name)
      } else {
        return [];
      }
    }
  }

}
</script>

<style scoped>

.task-complete-card {
  background-color: #d8fcee;
}

.task-late-status {
  color: red;

}

</style>
